@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@layer base { 
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-pointer
    }
}
.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}
.content-div:hover {
    background-image: linear-gradient(
        to right,
        rgba(222, 186, 176, 0.954),
        hsla(11, 64%, 85%, 0.885)   
    )!important;
}
#skills {
  padding-top: 60rem; 
  padding-bottom: 50rem; 
}

#work {
  padding-top: 45rem; 
  padding-bottom: 50rem;
}

#project {
  padding-top: 34rem;
  padding-bottom: 50rem; 
}


.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

#work, #project, #contact, #about {
  @apply section-padding;
}

.container {
  display:flex;
  justify-content: space-evenly;
  flex-wrap:wrap;
  min-height: 100vh;
}
